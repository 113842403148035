<template>
  <div class="widget-custom-in" v-if="!countryId">
    <selectComponent class="mr-6" placeholder="Выберите страну" :options="countryList" v-model="selectCountry" />
    <ButtonStock title="Перейти" @click="goToFromAllCountry" />
  </div>
  <div class="hot" v-else>
    <div v-if="summary">
      <ul class="indicators__list">
        <IndicatorItem
          v-for="item in indicatorList"
          :key="item.id"
          @click="activeTab = item.id"
          :active="item.id === activeTab"
          :percent="item.percent"
          :count="item.count"
          :title="item.title"
        />
      </ul>
      <div class="d-flex w-100 justify-content-end indicators__footer">
        <Select2 class="select2" :options="yearList" v-model="year" placeholder="Период" @select="loadData" />
        <ButtonStock class="mx-2" title="Страновая аналитика" @click="goTo" />
      </div>
    </div>

    <div style="margin-top: 40px" v-if="graphByYear">
      <div class="chart__inner" v-if="activeTab == 1 || activeTab == 2 || activeTab == 3 || activeTab == 5">
        <LineChartFour
          format-label=" млн $ США"
          :chart-data="graphByYear.data[0]"
          :chart-data-second="graphByYear.data[1]"
          :chart-data-three="graphByYear.data[2]"
          :chart-data-four="graphByYear.data[3]"
          background-color-three="#2F84EB"
          background-color-four="#31BC00"
          point-color-three="#2F84EB"
          point-color-four="#31BC00"
          background-color="#01A39D"
          point-color="#01A39D"
          background-color-two="#787F8F"
          point-color-two="#787F8F"
          border-color="#01A39D"
          border-color-two="#787F8F"
          border-color-three="#2F84EB"
          border-color-four="#31BC00"
          :labels="graphByYear.years"
          :displayDataLabels="false"
        />
        <div class="d-flex align-items-center mt-4">
          <div class="indicator-round d-flex align-items-center mr-4">
            <span class="rounded-circle"></span>
            <p>Товарооборот</p>
          </div>
          <div class="indicator-round d-flex align-items-center mr-4">
            <span class="rounded-circle"></span>
            <p>Экспорт</p>
          </div>
          <div class="indicator-round d-flex align-items-center mr-4">
            <span class="rounded-circle"></span>
            <p>Импорт</p>
          </div>
          <div class="indicator-round d-flex align-items-center">
            <span class="rounded-circle"></span>
            <p>Сальдо</p>
          </div>
        </div>
      </div>
      <div class="chart__inner" v-if="activeTab == 4">
        <LineChartDouble
          format-label=" млн $ США"
          :chart-data="graphByYear.data[4]"
          background-color="#01A39D"
          point-color="#01A39D"
          background-color-two="#787F8F"
          point-color-two="#787F8F"
          border-color="#01A39D"
          border-color-two="#787F8F"
          :labels="graphByYear.years"
          :displayDataLabels="false"
        />
        <div class="d-flex align-items-center mt-4">
          <div class="indicator-round d-flex align-items-center mr-4">
            <span class="rounded-circle"></span>
            <p>Абсолютное значение</p>
          </div>
          <!-- <div class="indicator-round d-flex align-items-center mr-4">
                  <span class="rounded-circle"></span>
                  <p>Прошедшее значение</p>
              </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import selectComponent from '@/common/components/selectComponent.vue';
  import text from '@/common/mixins/text';
  import Utils from '@/common/utils';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';
  import LineChartDouble from '@/components/lineChartDouble';
  import LineChartFour from '@/components/lineChartFour';

  import API from '../api/widgetElement';
  import IndicatorItem from '../components/indicatorItem';

  export default {
    name: 'Widget15',
    components: {
      selectComponent,
      ButtonStock,
      LineChartDouble,
      LineChartFour,
      IndicatorItem,
    },
    props: {
      countryId: {
        type: Number,
        default: null,
      },
    },
    watch: {
      countryId: function (newValue) {
        if (newValue > 0) this.loadYearList(true);
      },
    },
    mixins: [text],
    data() {
      return {
        countryList: [],
        selectCountry: null,
        activeTab: 1,
        indicatorList: [
          {
            id: 1,
            title: 'Товарооборот',
            count: null,
            percent: null,
          },
          {
            id: 2,
            title: 'Импорт',
            count: null,
            percent: null,
          },
          {
            id: 3,
            title: 'Экспорт',
            count: null,
            percent: null,
          },
          {
            id: 4,
            title: 'Несырьевой\n' + 'неэнергетический\n' + 'экспорт',
            count: null,
            percent: null,
          },
          {
            id: 5,
            title: 'Сальдо',
            count: null,
            percent: null,
          },
        ],

        year: new Date().getFullYear() - 1,
        yearList: [],
        summary: null,
        graphByYear: null,
      };
    },
    created() {
      Utils.loadSelectOptions('countryList', this.countryList);
      if (this.countryId > 0) this.loadYearList(true);
    },
    methods: {
      goToFromAllCountry() {
        if (this.selectCountry > 0) {
          this.$router.push({ name: 'countryAnalytics', query: { countryId: this.selectCountry } });
        }
      },
      loadYearList(thenLoadData) {
        this.summary = null;
        this.graphByYear = null;
        API.getAnaliticYearList().then((response) => {
          this.yearList = response.data;
          this.year = this.yearList.slice(-1)[0].id;
          if (thenLoadData) this.loadData();
        });
      },
      loadData() {
        this.summary = null;
        this.graphByYear = null;
        API.getAnaliticForeignTradeForOne(this.year, this.countryId).then((response) => {
          this.summary = response.data.summary;

          this.indicatorList[0].count = this.formatNumber(this.summary.totalValue / 1000000);
          this.indicatorList[0].percent = this.summary.totalValuePreviousPercent;

          this.indicatorList[1].count = this.formatNumber(this.summary.importValue / 1000000);
          this.indicatorList[1].percent = this.summary.importValuePreviousPercent;

          this.indicatorList[2].count = this.formatNumber(this.summary.exportValue / 1000000);
          this.indicatorList[2].percent = this.summary.exportValuePreviousPercent;

          this.indicatorList[3].count = this.formatNumber(this.summary.exportNonEnergyValue / 1000000);
          this.indicatorList[3].percent = this.summary.exportNonEnergyValuePreviousPercent;

          this.indicatorList[4].count = this.formatNumber(this.summary.balance / 1000000);
          this.indicatorList[4].percent = this.summary.balancePreviousPercent;

          this.graphByYear = {
            years: this.summary.valuesByYears.map((x) => x.year),
            data: [
              this.summary.valuesByYears.map((x) => (x.totalValue / 1000000).toFixed(1)),
              this.summary.valuesByYears.map((x) => (x.exportValue / 1000000).toFixed(1)),
              this.summary.valuesByYears.map((x) => (x.importValue / 1000000).toFixed(1)),
              this.summary.valuesByYears.map((x) => (x.balance / 1000000).toFixed(1)),
              this.summary.valuesByYears.map((x) => (x.exportNonEnergyValue / 1000000).toFixed(1)),
            ],
          };

          this.$forceUpdate();
        });
      },

      goTo() {
        if (this.countryId > 0) {
          this.$router.push({ name: 'countryAnalytics', query: { countryId: this.countryId } });
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/assets/theme/default/colors';

  .indicators {
    &__footer {
      flex-wrap: wrap;
    }
    &__list {
      gap: 16px;
      overflow: auto;
      padding: 24px 20px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }
  }

  .chart__inner {
    background: #ffffff;
    padding: 42px;
    box-shadow: 0px 0px 17px rgba(55, 55, 55, 0.08);
  }
  .chart__wrapper {
    padding: 18px;
    width: 100%;
  }
  .chart-stacked__wrapper {
    padding: 18px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @media (max-width: 768px) {
      min-width: 768px;
      align-items: flex-start;
    }
    p {
      margin-right: 130px;
      margin-bottom: 0;
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #343c44;
    }
  }

  .chart__header {
    display: flex;
    justify-content: center;
    font-family: 'Ubuntu', sans-serif;
    font-style: normal;
    font-size: 25px;
    line-height: 29px;
    color: $dark;
    @media (max-width: 576px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      span {
        margin-left: 0 !important;
      }
    }
    strong {
      font-weight: bold;
    }
    span {
      margin-left: 32px;
      font-size: 14px;
      strong {
        font-size: 25px;
        font-weight: bold;
      }
    }
    span:nth-child(3) {
      padding: 12px;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 113.9%;
      color: #ffffff;
      background: #ff5a5a;
      box-shadow: 0px 7px 17px rgba(84, 222, 0, 0.11);
      border-radius: 5px;
    }
  }
  .hot {
    .select2::v-deep {
      outline: none;
      max-width: 161px;
      width: 100%;
      @media (max-width: 576px) {
        max-width: 100%;
        margin: -12px 8px 12px 8px;
      }
      // Контейнер ширина
      .select2-container {
        outline: none;
        min-width: 161px;
        width: 100% !important;
        // Текст
        .select2-selection__rendered {
          outline: none;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 40px;
          color: #343c44;
        }
      }

      // Контейнер высота
      .select2-container--default .select2-selection--single {
        outline: none;
        height: calc(2.25rem + 6px) !important;
        border-radius: 2px !important;
        border: 1px solid #d6e4ec;
      }

      .select2-container--default .select2-selection--single .select2-selection__arrow {
        outline: none;
        top: 50% !important;
        transform: translateY(-50%) !important;
      }

      // Стрелочка
      .select2-container--default .select2-selection--single .select2-selection__arrow b {
        outline: none;
        border-color: unset;
        border-style: unset;
        border-width: unset;
        width: 10px;
        height: 10px;
        border-radius: 1px;
        border-left: 3px solid #343c44;
        border-bottom: 3px solid #343c44;
        transform: rotate(315deg);
        margin-left: -10px;
        margin-top: -6px;
      }

      // Плейсхолдер
      .select2-container--default .select2-selection--single .select2-selection__placeholder {
        outline: none;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: #343c44;
      }
    }
  }
  .text-inner {
    min-width: 420px;
    padding: 28px 16px 14px 16px;
    strong {
      display: block;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
    }
    span {
      padding-left: 16px;
      display: block;
      font-weight: normal;
      font-style: normal;
      font-size: 14px;
      line-height: 20px;
    }
  }
  .table-inner {
    min-width: 640px;

    width: 100%;
    margin-top: 12px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $dark;
    padding: 16px;
    .active {
      background-color: #ededed;
    }
    th {
      padding-bottom: 16px;
    }
    th:nth-child(1),
    td:nth-child(1) {
      padding-left: 12px;
    }
  }
  .projects__inner {
    padding: 18px;
    max-width: 45%;
  }
  .projects__head {
    display: flex;
    align-items: center;
  }
  .project__wrapper {
    min-width: 1024px;
    display: flex;
    justify-content: space-between;
  }
  .select {
    margin-left: 20px;
    width: auto;
    max-width: 320px;
    min-width: 100px;
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      margin-right: 16px;
      color: $color8;
    }
  }
  .indicator-round {
    span {
      background-color: #018bbf;
      width: 14px;
      height: 14px;
    }
    p {
      margin-left: 13px;
      font-family: 'Fira Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
    }
  }
  .indicator-round:nth-child(2) {
    p {
      margin-left: 13px;
    }
    span {
      background-color: #787f8f;
    }
  }
  .indicator-round:nth-child(3) {
    span {
      background-color: #2f84eb;
    }
  }
  .indicator-round:nth-child(4) {
    span {
      background-color: #31bc00;
    }
  }
</style>
